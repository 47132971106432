export default {
	SITE_KEYS: ['4sg', 'sky', 'fund'],
	StatusReasonIDs: {
		Limit2K: 201,
		DocumentExpired: 202,
		EEDRequired: 203,
		RequestedByRM: 204,
		FailedComplianceChecks: 205,
		ResidenceRiskJurisdiction: 206,
		CustomerReqRefundWithoutGameplay: 207,
		PersonOnSanctionsList: 208,
		SOWDecRequired: 209,
		SOWDecReceived: 210,
	},
	CLIENT_ACTIV_CODES: {
		's': 'SMS',
		'e': 'email',
		'c': 'code',
	},
	MGS_JP_GAME_PAY_ID: 0,
	MultiPage: {
		Promotions: {
			en: 'promotions',
			es: 'promociones'
		},
		Tournaments: {
			en: 'tournaments',
			es: 'torneos',
		},
	},
	UserFlags: {
		WithdrawDisabled: 1,
	    DepositDisabled: 2,
	    GamesDisabled: 3,
	},
	CurrencyIDs: {
		EUR: 1,
		USD: 3,
		MXN: 4,
		CAD: 5,
		GBP: 7,
		PEN: 8,
		INR: 9,
		ZAR: 10,
		AUD: 11,
		NZD: 12,
		BTC: 13,
	},
	BamboraPPID: 100,
	UITypes: {
		GR: 99,
		GR_INT: 9,
		BVI: 2,
		Bambora: 1,
	},
	PremierCashiers: {
		Dougflow: 0,
		PaymentIQ: 1,
	},
	JumioStatus: {
		Valid: 1,
		ValidWithAlert: 2,
		ValidationFailed: 3,
		Confirmed: 4,
		UploadPending: 5,
		RevalidationPending: 6,
		ReValidated: 7,
		PartialValid: 8,
	},
	ConversionStatus: {
		Success: 0,
        Error: 1,
        AccountAlreadyExists: 2,
	},
	PREMIER_CASHIER_ID: 80,
	MAX_CATEGORY_RECURSION: 3,
	GameInclusionErrorCodes: {
		SWF_OBJECT_NOT_LAUNCHE_GAME: 13,
	},
	CATEGORY_IDS: {
		TOP_LIST: 'top-list',
		TOP_LIST_LIVE: 'top-list-live',
		MAINHOME: 'main-home',
		HOMEPAGE: 'home',
		HOMEPAGE_LIVE: 'home-live',
		OFFERS: 'offers',
		TOURS: 'tours',
		FAVORITES: 'favorites',
		FAVORITES_CASINO: 'favorites-casino',
		FAVORITES_LIVE: 'favorites-live',
		FILTER: 'filter',
		CASINO: 10000,
		LIVE_CASINO: 10001,
		SLOTS: 1010,
		SUPPORT: 'support',
		PAYMENTS: 'payments',
		PROMO_INFO: 'promo-info',
		FAQ: 'faq',
		ABOUT: 'about',
		PROMOTIONS: 'promos',
		BLOG: 'blog',
	},
	Views: {
		MAIN: 0,
		CASHIER: 1,
		NO_SUPPORT: 8,
		TC: 9,
		BTC: 10,
		USER_UNSUBSCRIBE: 12,
		NO_SUPPORT_CY: 14,
		PAGE_NOT_FOUND: 15,
		BLOG: 16,
		FUND_LP: 17,
		FREE_BET: 18,
		CRM: 19,
		MANIFIESTO: 20,
		WELCOME_FS: 21,
		VCCP_REGISTER: 22,
		PLAYSTORE_REDIRECT: 23,
	},
	//used outside of the js dev, do NOT remove
	PRODUCT_ID: {
		FOUR_SG: {
			GR: 1,
			COM: 2,
		},
		BG: {
			M2C: 2,
			OSCAR: 3,
			ARIZONA: 4,
			ECUADOR: 5,
		},
	},
	GameTypes: {
		CASINO: 'Casino',
		LIVE: 'Live',
	},
	DEFAULT_LANG_VAL: 'en',
	Pages: {
		TOP_LIST: 'top-list',
		TOP_LIST_LIVE: 'top-list-live',
		MAINHOME: 'main-home',
		LOBBY: 'lobby',
		SPORTBETTING: 'sport-betting',
		PROMOS: 'promos',
		TOURS: 'tours',
		SUPPORT: 'support',
		PAYMENTS: 'payments',
		PROMO_INFO: 'promo-info',
		FAQ: 'faq',
		ABOUT: 'about',
		BLOG: 'blog',
		BLOG_SECTION: 'blog-section',
		BLOG_ALL: 'blog-all',
		BLOG_ARTICLE: 'blog-article',
		//SEPARATE_PAGE: 'separate-page',
	},
	Keys: {
		ESC: 27,
	},
	UserAuthStatuses: {
		LOGIN: 0,
		LOGOUT: 1,
		UNDETERMINED: 2,
	},	
	PlayerPromotions: {
		Types: {
			FreeGames: 5,
			FreePlays: 21,
			WOF: 22,
			FreeGamesWheel: 39,
			MixedWheel: 41,
		}
	},
	Date: {
		STR_LOCALE: 'hr-HR'
	},
	ProductIDs: {
		EgoeSlot: 0,
		EvolutionLive: 2,
		NetEntSlot: 3,
		NetEntLive: 4,
		Playson: 5,
		Microgaming: 6,
		Betsoft: 7,
		PragmaticPlay: 8,
		PragmaticLive: 9,
		OneXTwoGaming: 10,
		Oryx: 11,
		Gamomat: 12,
		Kalamba: 13,
		GoldenHeroGroup: 14,
		Givme: 15,
		QuickSpin: 16,
		TomHorn: 17,
		High5: 18,
		StakeLogic: 19,
		NoLimitCity: 20,
		Ezugi: 21,
		PlayNgo: 22,
		NetEntSlotOSS: 23,
		RedTigerOSS: 24,
		EvolutionOSS: 25,
		PlayTech: 26,
		PlayTechLive: 27,
		PariPlay: 28,
		Spinomenal: 29,
		Skywind: 30,
		PariPlayAinsworth: 31,
		ZITRO: 32,
		HabaneroDirect: 33,
		Swintt: 34,
		OSS: 35, //OPTIONAL TODO, remove NetEntSlotOSS, RedTigerOSS, EvolutionOSS and replace them with this one
		SSW: 36,
	},
	GameGroups: {
		Lobby: 0,
		CasinoSlotGames: 1,
		CasinoTableGames: 2,
		LiveGames: 4,
		SportBetting: 5,
		NetEnt: 9,
		NetEntLive: 10, 
		NetEntTable: 11, 
		NetEntVideoPoker: 12, 
		Microgaming: 13,
		Playson: 14,
		PlaysonTable: 15, 
		BetsoftSlot: 16,
		BetsoftTable: 17, 
		PragmaticPlay: 18, 
		PragmaticLive: 19, 
		NetEntBranded: 20,
		OneXTwoGaming: 21,
		AinsworthGames: 22,
		BoomingGames: 23,
		Gamevy: 24,
		RealisticGames: 25,	
		RedRakeGaming: 26,
		Wazdan: 27,
		Oryx: 28,
		IronDogStudios: 29,
		ProbabilityJones: 30,
		TomHorn: 31,
		Gamomat: 32,
		Kalamba: 33,
		GoldenHeroGroup: 34,
		Givme: 35,
		NoLimitCity: 37,
		High5: 38,
		StakeLogic: 39, 
		PG_Soft: 40,
		HackSaw_Gaming: 41,
		Spearhead_Studios: 42,
		Plank_Gaming: 43,
		QuickSpin: 44,
		RedTiger: 45,
		ISoftBet: 46,
		ThirdWave: 47,
		MicroGaming_Table: 48,
		OneXTwo_Games_Table: 49,
		Gamevy_Table: 50,
		Wazdan_Table: 51,
		RedTiger_Table: 53,
		ISoftBet_Table: 54,
		PragmaticVirtualSports: 55,
		Ezugi: 56,
		Ezugi_Oryx: 57,
		Evolution_Oryx: 58,
		RealisticGames_Table: 59,
		Habanero: 60,
		Habanero_Table: 61,
		PlayNgo: 62,
		PlayNgo_Table: 63,
		NetEntOSS: 65,
		RedTigerOSS: 66,
		EvolutionOSS: 67,
		PlayTech: 68,
		PlayTech_Table: 69,
		PlayTech_Live: 70,
		PariPlay: 71,
		Spinomenal: 72,
		Spinomenal_Table: 73,
		Skywind: 75,
		MicroGaming_Live: 78,
		SkywindLive: 79,
		TripleCherry: 80,
		Ballywulf: 81,
		Apparat: 82,
		Darwin: 83,
		Kalamba_Pariplay: 87,
		Anisworth_Pariplay: 88,
		G_Games: 84,
		G_Games_Scratch: 85,
		G_Games_Table: 86,
		Betixon: 89,
		Synot: 90,
		Revolver: 91,
		Amatic: 92,
		H5: 93,
		FBMDS: 94,
		FBMDS_Table: 95,
		FBMDS_Bingo: 96,
		PlayNgo_Bingo: 97,	
		G_Games_Bingo: 98,	
		Darwin_Bingo: 99,	 
		Pariplay_Bingo: 100,
		ZITRO_Slots: 101,
		ZITRO_Bingo: 102,
		Booming_Pariplay: 103,
		Wazdan_Pariplay: 104,
		Inspired_Pariplay: 105,
		Caleta_Pariplay: 106,
		HabaneroDirect: 109,
		HabaneroDirect_Table: 110,
		HabaneroDirect_VideoPoker: 111,
		Swintt: 112,
		Swintt_Live: 113,
		Thunderkick: 114,
		SkillzGames: 117,
		ESA_Gaming_Pariplay: 118,
		ESA_Gaming_Table_Pariplay: 119,
		OneXTwo_Games_Pariplay: 120,
		BTG: 121,
		NLC_OSS: 124,
		Spribe_Crash: 126,
		Boldplay: 127,
		AtomicSlotLab: 129,
		IndigoMagic: 130,
		Bluberi: 131,
		BlueGuru: 132,
		SegaSammy: 133,
		KingShowGames: 134,
		EzugiOSS: 135,
		BGaming_Softswiss: 136,
	},
	//used outside of the js dev, do NOT edit
	SiteIDs: {
		COM_4SG: 0,
		SKY_IO: 6,
		NET_4SC: 7,
		FUND_COM: 8,
		FUND_MX: 9,

		//these aren't being used in the wild at the moment, but we'll keep them in the mean time
		GR_4SG: 1,
		M2C: 2,
		OSCAR: 3,
		ARIZONA: 4,
		ECUADOR: 5,
	},
	UIIDs: {
		FOUR_SG: 0,
		BG: 1,
		SKY: 2,
		FUND: 3,
	},
	TRANS_HISTORY_STATUSES: {
		requested: 'info',
		approved: 'success',
		canceled: 'danger',
		returned: 'danger',
		declined: 'danger',
		pending: 'info',
		processed: 'success',
		error: 'danger',
		processedonprovider: 'info',
		void: 'default',
		waitinguser: 'info',
		timeout: 'warning',
		closed: 'success',
		rollbackbyprovider: 'info',
	},
	UIContexts: {
		freeBet: 'free-bet',
		RiskQuestionnaire: 'risk-questionnaire',
		CURRENCY_CONVER_START: 'currency-conver-start',
		CURRENCY_CONVER_POSTPONE: 'currency-conver-postpone',
		BONUS_POINTS: 'bonus-points',
		INFO_MSG: 'info-message',
		GAME_HISTORY: "game-history",
		GAME_PREVIEW: "game-preview",
		REGISTER: "register",
		INBOX: 'inbox',
		CASHIER: "cashier",
		ACCOUNT: "account",
		LOGIN: "login",
		SMS_ACTIVATION_FORM: "login-activation",
		LOGIN_FORGOTTEN_PASSWORD: "login-forgotten-pass",
		LOGIN_RESET_PASSWORD: "login-pass-reset",
		PROMO: 'promo-info',
		WALL_OF_FAME: 'wall-of-fame',
		USER_STATS: 'balance',
		DEPOSIT_PAYOUT: 'deposit-payout',
		DEPOSIT: 'deposit',
		DEPOSIT_SINGLE_PAGE: 'deposit-single-page',
		WITHDRAW: 'withdraw',
		TRANS_HISTORY: 'history',
		USER_PROFILE: 'profile',
		USER_NOTIF: 'user-notif',
		EDIT_BANK_ACC: 'bank-account',
		UPLOAD_DOCS: 'upload-docs',
		CHANGE_PASSWORD: 'change-password',
		LIMITS: 'responsible-gaming',
		FAQ: 'faq-modal',
		SELF_ASSESSMENT: 'self-assessment',
		LOTTERY_TICKETS: 'LOTTERY_TICKETS',
		REFERRAL_PAGE: 'REFERRAL_PAGE',
	},
	PPIDs: {
		X1: 104,
		NUVEI: 103,
		NEOSURF_M_PAY: 102,
		NEOSURF: 76,
		WIRE_TRANSFER: 6,
		PAY_VISION: 8,
		EASY_PAY: 25,
		BLUEBERRY: 61,
		NEWPAYSAFE: 70,
		BLUEBERRY_VOUCHER: 72,
		SOFORT: 81,
		ECO_PAYZ: 84,
		ECO_PAYZ_VOUCHER: 85,
		NETELLER: 88,
		SKRILLNEW: 89,
		PAYSAFECC: 91,
		TRUSTLY: 90,
		MUCHBETTER: 92,
		CASH_TO_CODE: 93,
		GIGADAT: 94,
		INTERAC: 95,
		INTERAC_EXPRESS: 96,
		RAVE: 97,
		SKRILL_TAP: 98,
		PAYSAFE_PCASHIER: 1000,
		VIVA_PCASHIER: 1001,
		ASTRO_PAY: 99,
		NETELLER_CY: 101,
	},
	Promos: {
		//used mostly outside of the 
		//build files, do not delete
		FIRST_DEPOSIT: 'FirstDeposit',
		WIN_WED: 'win-wed',
		SPIN_BOOSTER: 'spin-booster',
		RAKE_BACK: 'Cashback',
		FREQ_PLAYER: 'FrequentPlayerBonus',
		SPACE_BUS: 'ReferralBonus',
		RELOAD: 'reload',
		FREEBET: 'freebet',
		STARGATE_SPORTS: 'stargatesports',
		ACCABOOSTER: 'accabooster',
		RAKE_BACK_SPORTS: 'rake-back-sports',
	},
	RedirTypes: {
		PROMO: 'promo',
		GOTO: 'goto',
		GAME: 'game',
		FILTER: 'filter',
		LOGOUT: 'logout',
		CAT: 'cat',
		CAT_LEVEL_1: 'cat-level-1',
		URL: 'url',
	},
	GameThumbnail: {
		maxAnimDisplayNum: 6,
	},
	AccountStatusType: {
		ACTIVE : 1,
		REGISTERED : 2,
		DISABLED : 3,
		LOCKED : 4,
		VALIDATED : 5,
		PASSIVE : 6,
		PASSIVE_VALIDATED : 7,
		VALIDATION_REQUIRED : 10,
	},
	Limits: {
		Types: {
			DEPOSIT: 1,
			LOSS: 3,
			SESSION_TIMEOUT: 8,
			SELF_EXCLUSION: 4,
			REALITY_CHECK: 10,
		},
		Periods: {
			ONE_DAY: 1,
			TWO_DAYS: 10,
			ONE_WEEK: 2,
			ONE_MONTH: 3,
			TWO_WEEKS: 5,
			PERMANENT: 6,
			HOURS: 7,
			MINUTES: 8,
			SIX_MONTHS: 9,
			YEAR: 4,
		},
	},
	ServerMessageCodes: {
		ACCOUNT_NOT_ACTIVE: 60506,
		FORBIDDEN_IP: 10041,
	},
	WebGL: {
		ENABLED: 'enabled',
		DISABLED: 'disabled',
		UNSUPPORTED: 'unsupported',
	},
	PaymentTypes: {
		DEPOSIT: 'deposit',
		WITHDRAW: 'withdrawal',
	},
	ButtonTypes: {
		INFO: 'info',
		SUCCESS: 'success',
	},
	LogoutTypes: {
		SessionTimeout: 1,
		UserLogout: 2,	
		NewLogin: 3,
		LogoutByBOSUser: 4,
		SessionTimeoutLimit: 5,
		Other: 6,
		Error: 7,
	},
};